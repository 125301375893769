/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Link from '../../link';

import './style.scss';

/**
 * Community Link Component.
 *
 * @param {Object} link Link props.
 *
 * @return {jsx}
 */
const CommunityLink = ( { link } ) => {

	const { ctaLink } = link;

	return (
		<section className="community-sections community-link">
			<div className="row">
				<div className="small-12 columns">
					<Link { ...ctaLink } withArrow={ true } />
				</div>
			</div>
		</section>
	);

};

CommunityLink.propTypes = {
	link: PropTypes.object,
};

CommunityLink.defaultProps = {
	link: {
		ctaLink: {}
	}
};

export default CommunityLink;
