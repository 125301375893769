/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Image from './../image/index';
import { sanitize } from './../../utils/functions';

import './style.scss';

/**
 * FeatureRow Component.
 *
 * @param {array} meta Component props.
 *
 * @return {jsx}
 */
const FeatureRow = ( { meta } ) => {

	return (
		<section className="feature-row">
			<div className="row">
				<div className="small-12 columns">
					<div className="feature-row__lists">
						{ meta.map( ( data, index ) => (
							<div className="row feature-row__item" key={ `${ data.headline }-${ index }` }>
								<div className="medium-4 small-12 columns">
									<hr className="feature-row__separator"/>
									<h2 className="feature-row__headline" dangerouslySetInnerHTML={ { __html: sanitize( data.headline ) } } />
									<div className="feature-row__description" dangerouslySetInnerHTML={ { __html: sanitize( data.description ) } } />
								</div>
								<div className="medium-8 small-12 columns">
									<figure className="feature-row__figure">
										<Image { ...data.imageDesktop } className="feature-row__image hide-for-small-only" />
										<Image { ...data.imageMobile } className="feature-row__image show-for-small-only" />
									</figure>
								</div>
							</div>
						) ) }
					</div>
				</div>
			</div>
		</section>
	);

};

FeatureRow.propTypes = {
	meta: PropTypes.array,
};

FeatureRow.defaultProps = {
	meta: [
		{
			headline: '',
			description: '',
			imageDesktop: {
				altText: '',
				sourceUrl: '',
				srcSet: '',
				sizes: '',
				mediaDetails: {
					height: '',
					width: ''
				}
			},
			imageMobile: {
				altText: '',
				sourceUrl: '',
				srcSet: '',
				sizes: '',
				mediaDetails: {
					height: '',
					width: ''
				}
			}
		}
	]
};

export default FeatureRow;
