/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import CommunityBanner from '../../components/community/banner';
import FeatureRow from './../../components/features-row';
import CommunityLink from '../../components/community/link';
import SEO from "../../components/seo";
import { getOgImage } from "./../../utils/functions";
import FooterCTA from './../../components/footer-cta';
import ConversationAdvertForm from '../../components/conversation-based-advertising/form';

import "./style.scss";

/**
 * Community Template Page.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const Community = (props) => {

	const {
		pageContext: {
			page,
			page: {
				communityPageMeta: {
					featureList,
					linkWrapper
				},
				seo,
				uri,
				contactUsFormMeta: { contactForm, ctaLink },
				footerCtaLinks: { footerCtaWrapper },
			}
		}
	} = props;

	const openGraphImage = getOgImage(seo);

	return (
		<Layout title="Community" uri={uri} className="archive archive-single">
			<SEO
				title={page.title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			<CommunityBanner page={page} ctaLink={ctaLink} tagParent="PUBLISHERS" tagName="Loyalty_Contact" />
			<FeatureRow meta={featureList} />
			{linkWrapper.ctaLink && <CommunityLink link={linkWrapper} />}
			{footerCtaWrapper && <section className="community-cta">
				<FooterCTA links={footerCtaWrapper} />
			</section>}
			<ConversationAdvertForm meta={contactForm} tagParent="PUBLISHERS" tagName="Loyalty_Submit" />

		</Layout>
	)
};

Community.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default Community;
